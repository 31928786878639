<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import type { FormKitNode } from '@formkit/core'
import type {
  IManagerFormDataItem,
  INodeGroupTable,
  INodeGroupTableModal,
  INodeObject,
} from '@manager'
import { getModalConfig } from '../utils'

interface Props {
  node: INodeGroupTable
  objectNode: INodeObject
  path: string
  value?: IManagerFormDataItem | undefined
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'confirm', data: IManagerFormDataItem): void
  (e: 'close'): void
}>()

const form = ref<{ node: FormKitNode }>()

const submitForm = async (data: IManagerFormDataItem) => {
  emit('confirm', data)
}

const config = ref<Required<INodeGroupTableModal>>({
  title: '',
  body: '',
  confirmButton: '',
})

if (props.value) {
  config.value = getModalConfig(props.node.actions?.edit, {
    title: 'Update',
    confirmButton: 'Update',
  })
} else {
  config.value = getModalConfig(props.node.actions?.add, {
    title: 'Add',
    confirmButton: 'Add',
  })
}
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="
      {
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': '-translate-y-6 opacity-0',
        'enter-to-class': 'translate-y-0 opacity-100',
        'leave-active-class': 'transition duration-300 ease-in-out transform',
        'leave-to-class': 'translate-y-6 opacity-0',
        'leave-from-class': '-translate-y-0 opacity-100',
      } as any
    "
  >
    <ModalContainer :title="config.title" close-on-click @close="emit('close')">
      <FormKit
        ref="form"
        type="form"
        :actions="false"
        class="flex"
        :value="value"
        @submit="submitForm"
      >
        <div class="grid grid-cols-6 gap-4">
          <ManagerNode
            v-for="childNode in objectNode.nodes"
            :key="resolveNodeId(childNode)"
            :node="childNode"
            :parent-path="path"
            :parent-node="objectNode"
          />
        </div>

        <div class="mt-6 flex justify-end gap-2">
          <Button color="transparentSecondary" @click.prevent="emit('close')">
            Cancel
          </Button>
          <Button
            type="submit"
            class="mr-2"
            @click.prevent="form?.node.submit()"
          >
            {{ config.confirmButton }}
          </Button>
        </div>
      </FormKit>
    </ModalContainer>
  </VueFinalModal>
</template>
